import React from 'react'
import styled from 'styled-components'
import { BaseButton } from '../Section'

const LightGrayBtn = styled(BaseButton)`		
	background-color:#d4d6e3;
	color:#363636;
	&:before {
		background: #EEF0FD;
	}
	& .icon{
		> svg{
			fill:#363636;
		}
	}
`
const LightGrayButton = (props) => {
	const { icon, iconAfter, text, size } = props;
	return(
		<LightGrayBtn className={`btn ${size ? `btn-${size}` : ''} `}>
			{ iconAfter ? (
				<>
					{ text && <span className='text'>{text}</span>}
					{ iconAfter && <span className='icon'>{iconAfter}</span>}
				</>
				):(
					<>
						{ icon && <span className='icon'>{icon}</span>}
						{ text && <span className='text'>{text}</span>}
					</>
				)
			}
		</LightGrayBtn>
	)
}


export default LightGrayButton

