import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { AreaIcon, QanswerIcon, KeyIcon, CoinIcon } from "../Icons";

const RecommendWrap = styled.div`
    margin:0 auto 30px auto;
    @media (min-width: 992px) {
        max-width:450px;
        width:100%;
    }
`
const RecommendTitle = styled.div`
    margin-bottom:10px;
    font-weight:700;    
    font-size: 20px;
    line-height: 30px;
    @media (min-width: 1200px) {
        font-size: 22px;
        line-height: 32px;
    }

`
const ListRecommend = styled.div`
    margin-bottom:30px;
`
const ListItemRecommend = styled(Link)`
    display:flex;
    flex-wrap: wrap;
    align-items:center;
    .icon{
        display:flex;
        align-items:center;
        justify-content:center;
        background-color:#E9242A;
        border-radius:6px;
        @media (min-width: 992px) {
            border-radius:10px;
        }
        > svg{
            fill:#fff;
            @media (max-width: 991.98px) {
                height: 24px;
            }
        }
        + .text{
            margin-left:10px;
        }
        width:40px;
        height:40px;
        min-width:40px;
        min-height:40px;
		@media (min-width: 768px) {
            width:60px;
            height:60px;
		}
		@media (min-width: 992px) {            
            width:70px;
            height:70px;
		}
    }
    .text{
        background-color:#373636;
        color:#fff;
        font-size: 16px;
		line-height: 30px;
        display:flex;
        flex-wrap: wrap;
        align-items:center;
        padding:0 15px;
        min-height:40px;
        border-radius:6px;
        @media (min-width: 992px) {
            border-radius:10px;
        }

        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
		@media (min-width: 768px) {
            font-size: 18px;
			line-height: 30px;
            padding:0 20px;
            min-height:60px;
		}
		@media (min-width: 992px) {
            font-size: 20px;
			line-height: 30px;
            padding:0 25px;
            min-height:70px;
		}
		@media (min-width: 1200px) {
            padding:0 30px;
			font-size: 22px;
			line-height: 32px;
		}

    }
    &:hover{
        .icon{
            background-color:#373636;
        }
        .text{
            background-color:#E9242A;
        }
    }
    & + .list-item{
        margin-top:15px;
        @media (min-width: 992px) {
            margin-top:30px;
        }
    }
`
const Recommendations = () => {
  return (
    <RecommendWrap>
        <RecommendTitle>Other Recommendations</RecommendTitle>
        <ListRecommend className="list-recommend">
            <ListItemRecommend to="/service-area" className="list-item">
                <span className="icon"><AreaIcon /></span>
                <span className="text">Service Area</span>
            </ListItemRecommend>
            <ListItemRecommend to="/faqs" className="list-item">
                <span className="icon"><QanswerIcon /></span>
                <span className="text">Building FAQs</span>
            </ListItemRecommend>
            <ListItemRecommend to="/rent-to-own-metal-buildings/" className="list-item">
                <span className="icon"><KeyIcon /></span>
                <span className="text">Apply for Rent-to-Own</span>
            </ListItemRecommend>
            <ListItemRecommend to="/financing/" className="list-item">
                <span className="icon"><CoinIcon /></span>
                <span className="text">Apply for Financing</span>
            </ListItemRecommend>
        </ListRecommend>
    </RecommendWrap>
  );
};
export default Recommendations;
