import React from "react";
import styled from "styled-components";
const Badge = styled.span`
  cursor: pointer;
  border: 1px solid #c8cce6;
  background-color: #fff;
  color: #373636;
  font-weight: 500;
  display: inline-block;
  &:hover,
  &:focus,
  &.active {
    background-color: #e9242a;
    border-color: #e9242a;
    color: #fff;
  }

  &.badge-active {
    background-color: #f9141a;
    border-color: #f9141a;
    color: #fff;
  }
`;

const BadgeList = (props) => {
  const { badgeText, size, badeColor, ...others } = props;
  return (
    <Badge
      className={`badge ${size ? `badge-${size}` : ""} ${
        badeColor ? `badge-${badeColor}` : ""
      } `}
      {...others}
    >
      {badgeText}
    </Badge>
  );
};
export default BadgeList;
