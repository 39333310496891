import React from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { DetailIcon } from "../Icons";
import {
  ProductCard,
  ProductBody,
  ProductFooter,
  ProductSku,
  ProductFigure,
  ProductDtl,
  ProductName,
} from "../ProductStyle";
import DefaultButton from "../Button/DefaultButton";

const SimilarModal = ({ productData }) => {
  return (
    <ProductCard className="card card-grid">
      <ProductBody className="card-body">
        <ProductFigure className="card-figure">
          <ProductSku className="product-sku">
            SKU : {productData["skuNumber"]}
          </ProductSku>
          <Link to={productData.url}>
            <GatsbyImage
              image={productData.image.gatsbyImageData}
              alt={productData.title}
            />
          </Link>
          <ProductDtl className="product-dtl">
            <Link to={productData.url}>
              <DefaultButton
                icon={<DetailIcon />}
                text="View Details"
                size="xs"
              />
            </Link>
          </ProductDtl>
        </ProductFigure>
      </ProductBody>
      <ProductFooter className="card-footer">
        <ProductName className="product-name"><Link to={productData.url}>{productData.title}</Link></ProductName>
      </ProductFooter>
    </ProductCard>
  );
};
export default SimilarModal;
