import React, { useContext, useEffect } from "react";
import { CartIcon } from "../Icons";
import DefaultButton from "../Button/DefaultButton"
import { Context } from "../context"
import { navigate } from "gatsby";
import styled from "styled-components";

const AddCartButton = styled.div`
    &:hover {
        cursor: pointer;
    }
`

const AddToCart = ({ product }) => {
    const {cartData, setCartData} = useContext(Context);
    const addToCart = () => {
        let currentCartData = cartData?.map((cartItem) => cartItem );
        if(currentCartData?.length > 0) {
            let found = cartData.find((cartItem) => cartItem.id === product.id);
            if (found) {
                currentCartData = currentCartData.map((cartItem) =>
                cartItem.id === product.id
                    ? { ...cartItem, count: cartItem.count + 1 }
                    : cartItem
                )
            } else {
                currentCartData.push({ ...product, count: 1 });
            }
        } else {
            currentCartData.push({ ...product, count: 1 });
        }
        setCartData(currentCartData);
        navigate("/cart");
    }
    useEffect(() => {
        localStorage.setItem('cartData', JSON.stringify(cartData))
    }, [cartData, setCartData]);

    return(
        <AddCartButton onClick={() => addToCart()} className="btn-block"><DefaultButton iconAfter={<CartIcon />} text="Add to Cart" size="xl" /></AddCartButton> 
    )
};

export default AddToCart