import React from "react";
import Seo from "../components/seo";
import Layout from "../components/layout";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import { Link, graphql, navigate } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import {
  Section,
  Container,
  MarkdownContent,
  BreadCrumb,
} from "../components/Section";
import { ProductSku } from "../components/ProductStyle";
import {
  Sidebar,
  SidebarDefault,
  SidebarDefaultBody,
  BuildingImg,
  BuildingTexture,
} from "../components/Sidebar";
import {
  HomeIcon,
  RoofIcon,
  GaugeIcon,
  ManufacurerIcon,
  MapPointerIcon,
  TickIcon,
} from "../components/Icons";
import SimilarBuildingModal from "../components/SimilarModal";
import Badge from "../components/Badge";
import LightGrayButton from "../components/Button/LightGrayButton";
import RequestForm from "../components/RequestForm";
import AddToCart from "../components/AddToCart";
import Recommendations from "../components/Recommendations";
import PhoneCta from "../components/PhoneCta";

const ProductDtl = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
  @media (min-width: 1200px) {
    margin: 0 -20px;
  }
`;
const ProducLeftDtl = styled.div`
  position: relative;
  width: 100%;
  padding: 0 15px;
  @media (min-width: 992px) {
    flex: 0 0 50%;
    max-width: 50%;
    .product-name {
      display: none;
    }
  }
  @media (min-width: 1200px) {
    padding: 0 20px;
  }
  @media (min-width: 1600px) {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
`;
const ProducRightDtl = styled.div`
  position: relative;
  width: 100%;
  padding: 0 15px;
  @media (min-width: 992px) {
    flex: 0 0 50%;
    max-width: 50%;
  }
  @media (min-width: 1200px) {
    padding: 0 20px;
  }
  @media (max-width: 991.98px) {
    .product-name {
      display: none;
    }
  }
  @media (min-width: 1600px) {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
`;

const ProductCard = styled.div`
  position: relative;
`;
const ProductBody = styled.div`
  position: relative;
`;
const ProductFigure = styled.div`
  position: relative;
  overflow: hidden;
  .gatsby-image-wrapper {
    border-radius: 10px;
  }
`;
const ProductFooter = styled.div`
  padding: 20px 0;
`;
const ListBadge = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -5px;
  .badge {
    margin: 5px;
  }
`;
const RequestFormWrap = styled.div`
  position: relative;
  z-index: 1;
  .form-title {
    color: #fff;
    margin-bottom: 0;
  }
  .form-subtitle {
    color: #fff;
  }
  .form-action {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    .btn {
      font-weight: 500;
      @media (min-width: 1600px) {
        > .text {
          padding: 12px 50px;
          font-size: 22px;
          line-height: 32px;
        }
      }
    }
  }
  .form-control {
    background-color: #232323;
    color: #fff;
    border-color: #232323;
    &::placeholder {
      color: #fff;
      font-weight: 500;
    }
    &:focus,
    &:active {
      outline: none;
      box-shadow: none;
      border-color: #232323;
      &::placeholder {
        color: #fff;
      }
    }
  }

  .css-13cymwt-control,
  .css-t3ipsp-control {
    background-color: #232323 !important;
    color: #fff !important;
    border-color: #232323 !important;
  }
  .css-t3ipsp-control {
    border-color: #232323 !important;
  }
  .css-qbdosj-Input,
  .css-19bb58m {
    color: #fff !important;
  }
  .css-1dimb5e-singleValue {
    color: #fff !important;
  }
  .css-1jqq78o-placeholder {
    color: #fff !important;
  }
  .css-1nmdiq5-menu {
    background-color: #232323 !important;
  }
`;

const ProductName = styled.h1`
  margin-bottom: 10px;
  text-transform: inherit;
  margin-bottom: 10px;
  font-size: 24px;
  line-height: 34px;
  @media (min-width: 1200px) {
    font-size: 26px;
    line-height: 36px;
  }
  @media (min-width: 1600px) {
    font-size: 28px;
    line-height: 38px;
    margin-bottom: 15px;
  }
`;
const ProductNameMobile = styled.div`
  font-family: "Oxanium", serif;
  font-weight: 700;
  margin-bottom: 10px;
  text-transform: inherit;
  margin-bottom: 10px;
  font-size: 24px;
  line-height: 34px;
`;

const ProductCta = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -5px 15px -5px;
  a,
  button {
    width: 100%;
    .btn {
      width: 100%;
    }
  }
  @media (min-width: 1200px) {
    margin: 0 -10px 20px -10px;
  }
  @media (min-width: 1600px) {
    margin: 0 -10px 30px -10px;
  }
`;
const ProductItemCta = styled.div`
  position: relative;
  width: 100%;
  padding: 0 5px;
  @media (min-width: 576px) {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  @media (min-width: 1200px) {
    padding: 0 10px;
  }
  &.product-item-cta {
    + .product-item-cta {
      margin-top: 15px;
      @media (min-width: 576px) {
        margin-top: 0;
      }
    }
  }
`;
const ListInfo = styled.div`
  margin-bottom: 30px;
  text-align: left;
`;
const ListItemInfo = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: self-start;
  color: #373636;
  font-size: 14px;
  line-height: 20px;
  @media (min-width: 1200px) {
    font-size: 16px;
    line-height: 28px;
  }
  a {
    display: inline-block;
    color: #373636;
    position: relative;
    &:hover {
      color: #e9242a;
    }
  }
  + .list-item {
    margin-top: 8px;
    @media (min-width: 1200px) {
      margin-top: 10px;
    }
  }
  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    @media (min-width: 1200px) {
      height: 28px;
    }
    > svg {
      fill: #e9242a;
      width: 20px;
    }
    + .text {
      margin-left: 15px;
    }
  }
  .text {
    b {
      font-weight: 600;
    }
  }
`;

const PriceBox = styled.div`
  margin-bottom: 30px;
  border-radius: 10px;
  overflow: hidden;

  background-image: repeating-linear-gradient(
      0deg,
      #eef0fd,
      #eef0fd 10px,
      transparent 10px,
      transparent 20px,
      #eef0fd 20px
    ),
    repeating-linear-gradient(
      90deg,
      #eef0fd,
      #eef0fd 10px,
      transparent 10px,
      transparent 20px,
      #eef0fd 20px
    ),
    repeating-linear-gradient(
      180deg,
      #eef0fd,
      #eef0fd 10px,
      transparent 10px,
      transparent 20px,
      #eef0fd 20px
    ),
    repeating-linear-gradient(
      270deg,
      #eef0fd,
      #eef0fd 10px,
      transparent 10px,
      transparent 20px,
      #eef0fd 20px
    );
  background-size: 3px 100%, 100% 3px, 3px 100%, 100% 3px;
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-repeat: no-repeat;
`;
const PriceInnerBox = styled.div`
  position: relative;
  padding: 20px;
  &:before {
    position: absolute;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    top: 2px;
    left: 2px;
    content: "";
    background-color: #eef0fd;
  }
`;

const PriceInfo = styled.div`
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  border-bottom: 1px solid #fff;
  align-items: center;
  margin-bottom: 15px;
  padding-bottom: 10px;
  @media (min-width: 576px) {
    margin-bottom: 20px;
    padding-bottom: 15px;
  }
`;
const ProductPrice = styled.div`
  position: relative;
  flex: 0 0 auto;
  width: auto;
  max-width: none;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-start;
  @media (min-width: 576px) {
    align-items: center;
    flex-wrap: nowrap;
    flex-direction: row;
  }
  small {
    font-weight: 600;
    color: #373636;
    font-size: 14px;
    @media (min-width: 1200px) {
      font-size: 16px;
    }
    @media (min-width: 1600px) {
      font-size: 18px;
    }
    + strong {
      @media (min-width: 576px) {
        margin-left: 10px;
      }
    }
  }
  strong {
    font-weight: 700;
    color: #e9242a;
    font-size: 24px;
    line-height: 28px;
    @media (min-width: 768px) {
      font-size: 28px;
      line-height: 32px;
    }
    @media (min-width: 1200px) {
      font-size: 32px;
      line-height: 36px;
    }
  }
`;
const RtoInfo = styled.div`
  position: relative;
  text-align: left;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 -15px;
  @media (min-width: 1200px) {
    margin: 0 -15px;
  }
`;
const RtoItemInfo = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  padding: 0 15px;

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    > svg {
      fill: #e9242a;
      width: 20px;
    }
    + .text {
      margin-left: 15px;
    }
  }
  .text {
    font-size: 14px;
    line-height: 24px;
    font-weight: 600;
    @media (min-width: 1200px) {
      font-size: 16px;
      line-height: 24px;
    }
  }
`;
const HelpText = styled.div`
  position: relative;
  font-weight: 500;
  color: #a0a2b2;
  font-size: 12px;
  line-height: 18px;
  margin-left: 10px;
  @media (min-width: 576px) {
    margin-left: 20px;
  }
`;
const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
  @media (min-width: 1200px) {
    margin: 0 -30px;
  }
`;
const LeftGrid = styled.div`
  position: relative;
  width: 100%;
  padding: 0 15px;
  @media (min-width: 992px) {
    flex: 0 0 50%;
    max-width: 50%;
  }
  @media (min-width: 1200px) {
    padding: 0 30px;
  }
  @media (max-width: 991.98px) {
    .similar-modal {
      display: none;
    }
  }
`;
const RightGrid = styled.div`
  position: relative;
  width: 100%;
  padding: 0 15px;
  @media (min-width: 992px) {
    flex: 0 0 50%;
    max-width: 50%;
    .similar-modal {
      display: none;
    }
  }
  @media (min-width: 1200px) {
    padding: 0 30px;
  }
`;

const SimilarGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
  @media (min-width: 1200px) {
    margin: 0 -15px;
  }
  .card {
    border: none;
    .card-figure {
      .gatsby-image-wrapper {
        border-radius: 5px 5px 0 0;
      }
    }
    .card-body {
      padding: 0;
    }
    .card-footer {
      /* background-color:#373636; */
      padding: 20px;
      + .card-footer {
        padding-top: 20px;
      }
    }

    .product-name {
      /* color:#fff; */
      @media (min-width: 768px) {
        font-size: 18px;
        line-height: 26px;
      }
    }
  }
`;

const SimilarGridItem = styled.div`
  position: relative;
  z-index: 100;
  width: 100%;
  padding: 0 10px;
  @media (min-width: 576px) {
    max-width: 50%;
    flex: 0 0 50%;
  }
  @media (min-width: 992px) {
    padding: 0 15px;
  }
`;
const SimilarModal = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 30px -10px 0 -10px;
  @media (min-width: 1200px) {
    margin: 60px -15px 0 -15px;
  }
  @media (min-width: 1600px) {
    margin: 100px -15px 0 -15px;
  }
`;
const SimilarTitle = styled.h2`
  text-transform: capitalize;
  font-size: 18px;
  line-height: 30px;
  @media (min-width: 768px) {
    font-size: 20px;
    line-height: 30px;
  }
  @media (min-width: 992px) {
    font-size: 24px;
    line-height: 34px;
  }
  @media (min-width: 1200px) {
    font-size: 30px;
    line-height: 40px;
  }
  @media (min-width: 1600px) {
    font-size: 36px;
    line-height: 46px;
  }
`;

const MarkdownContents = styled(MarkdownContent)`
  h2 {
    text-transform: capitalize;
  }
`;
const BuildingDescription = styled.div``;

const ProductTemplatePage = ({ location, data }) => {
  const productData = data.contentfulProduct;

  const totalProducts = data.allContentfulProduct.edges;

  const categories = productData.productCategories.map((cat) => cat.title);
  const relatedProducts = totalProducts.filter((product) => {
    if (product.node.id === productData.id) return false;
    const productCategories = product.node.productCategories.map(
      (cat) => cat.title
    );
    const commonCategories = productCategories.filter((item) =>
      categories.includes(item)
    );
    if (commonCategories && commonCategories.length) return true;
    return false;
  });

  const schema = {
    "@context": "https://schema.org/",
    "@type": "Product",
    name: productData.title,
    image: productData.image.fluid.srcWebp,
    description:
      "Pack, take a look at this" +
      productData.title +
      " in " +
      productData.city +
      ", " +
      productData.state +
      " " +
      productData.zipcode,
    sku: productData.skuNumber,
    offers: {
      "@type": "Offer",
      url: "https://www.engineeredmetalbuildings.com" + productData.url,
      priceCurrency: "USD",
      price: productData.price,
      availability: "https://schema.org/InStock",
      itemCondition: "https://schema.org/NewCondition",
    },
    review: {
      "@type": "Review",
      reviewRating: {
        "@type": "Rating",
        ratingValue: "5",
        bestRating: "5",
      },
      author: {
        "@type": "Person",
        name: "CTC",
      },
    },
    aggregateRating: {
      "@type": "AggregateRating",
      ratingValue: "5",
    },
  };

  return (
    <Layout location={location}>
      <Seo
        title={productData.metaTitle}
        description={productData.metaDescription}
        schemaMarkup={schema}
      />
      <Section
        ept="160px"
        epb="30px"
        xpt="140px"
        xpb="20px"
        pt="90px"
        pb="15px"
        bgColor="#fff"
        className="section-product"
      >
        <Container>
          <BreadCrumb className="black">
            <Link to="/">Home</Link>
            <Link to="/shop-metal-buildings">Shop Metal Buildings</Link>
            <span>{productData.title}</span>
          </BreadCrumb>
          <ProductDtl>
            <ProducLeftDtl>
              <ProductNameMobile className="product-name">
                {productData.title}
              </ProductNameMobile>
              <ProductCard className="card">
                <ProductSku className="sku-lg">
                  SKU : {productData.skuNumber}
                </ProductSku>
                <ProductBody className="card-body">
                  <ProductFigure className="card-figure">
                    <GatsbyImage
                      image={productData.image.gatsbyImageData}
                      alt={productData.title}
                    />
                  </ProductFigure>
                </ProductBody>
                <ProductFooter className="card-footer">
                  <ListBadge>
                    {(productData["buildingTags"] || []).map((item) => (
                      <Badge
                        key={item}
                        badgeText={item}
                        onClick={() =>
                          navigate("/shop-metal-buildings", {
                            state: { tag: item },
                          })
                        }
                      />
                    ))}
                  </ListBadge>
                </ProductFooter>
              </ProductCard>
            </ProducLeftDtl>
            <ProducRightDtl>
              <ProductName className="product-name">
                {productData.title}
              </ProductName>
              <PriceBox>
                <PriceInnerBox>
                  <PriceInfo>
                    <ProductPrice>
                      <small>Starting Price:</small>{" "}
                      <strong>
                        ${productData.price.toLocaleString("en-US")}*
                      </strong>
                    </ProductPrice>
                    <HelpText>
                      (Prices vary by state, location, & customization)
                    </HelpText>
                  </PriceInfo>
                  <RtoInfo className="rto-info">
                    {productData.rto && (
                      <RtoItemInfo className="rto-item">
                        <span className="icon">
                          <TickIcon />
                        </span>
                        <span className="text">RTO / Financing</span>
                      </RtoItemInfo>
                    )}
                    <RtoItemInfo className="rto-item">
                      <span className="icon">
                        <TickIcon />
                      </span>
                      <span className="text">
                        Delivery & Installation Included
                      </span>
                    </RtoItemInfo>
                  </RtoInfo>
                </PriceInnerBox>
              </PriceBox>

              <ListInfo className="list-info">
                <ListItemInfo className="list-item">
                  <span className="icon">
                    <MapPointerIcon />
                  </span>
                  <span className="text">
                    Building Location:{" "}
                    <b>
                      {" "}
                      {productData.city}, {productData.state}{" "}
                      {productData.zipcode}
                    </b>
                  </span>
                </ListItemInfo>
                <ListItemInfo className="list-item">
                  <span className="icon">
                    <GaugeIcon />
                  </span>
                  <span className="text">
                    Gauge:{" "}
                    <b>
                      {" "}
                      {productData["gaugeTubing"] || 0}-Gauge Tubing,{" "}
                      {productData["gaugePanels"] || 0}-Gauge Panels (Upgrade
                      Available)
                    </b>
                  </span>
                </ListItemInfo>
                <ListItemInfo className="list-item">
                  <span className="icon">
                    <ManufacurerIcon />
                  </span>
                  <span className="text">
                    Manufacturer: <b> {productData["manufacturer"]}</b>
                  </span>
                </ListItemInfo>
                <ListItemInfo className="list-item">
                  <span className="icon">
                    <RoofIcon />
                  </span>
                  <span className="text">
                    Roof Style: <b> {productData["roofStyleType"]}</b>
                  </span>
                </ListItemInfo>
                <ListItemInfo className="list-item">
                  <span className="icon">
                    <HomeIcon />
                  </span>
                  <span className="text">
                    Condition:<b> {productData["buildingCondition"]}</b>
                  </span>
                </ListItemInfo>
              </ListInfo>

              <ProductCta>
                <ProductItemCta className="product-item-cta">
                  <a
                    href="https://engineeredmetalbuildings.sensei3d.com/"
                    target="_blank"
                    rel="noreferrer"
                    aria-label="Start Building"
                  >
                    <LightGrayButton text="Customize Now" size="xl" />
                  </a>
                </ProductItemCta>
                <ProductItemCta className="product-item-cta">
                  <AddToCart product={productData} />
                  {/* <QuotePopUpButton thanksURL="/thank-you-product-quote/" text="Get a Quote" icon={<QuoteIcon />} size="xl" /> */}
                </ProductItemCta>
              </ProductCta>
            </ProducRightDtl>
          </ProductDtl>
        </Container>
      </Section>
      <Section
        ept="30px"
        epb="60px"
        xpt="20px"
        xpb="40px"
        pt="15px"
        pb="30px"
        bgColor="#fff"
        className="section-product-desc"
      >
        <Container>
          <Grid>
            <LeftGrid>
              <MarkdownContents>
                <h2>Building Description</h2>
                {!!productData.description && (
                  <BuildingDescription
                    dangerouslySetInnerHTML={{
                      __html: productData.description.childMarkdownRemark.html,
                    }}
                  />
                )}
              </MarkdownContents>
              {relatedProducts.length > 0 && (
                <SimilarModal className="similar-modal">
                  <SimilarTitle>Check Out Similar Building Models</SimilarTitle>
                  <SimilarGrid className="similar-grid">
                    {relatedProducts.slice(0, 4).map((pd, i) => (
                      <SimilarGridItem key={i} className="similar-item">
                        <SimilarBuildingModal productData={pd.node} />
                      </SimilarGridItem>
                    ))}
                  </SimilarGrid>
                </SimilarModal>
              )}
            </LeftGrid>
            <RightGrid>
              <Sidebar className="isSticky">
                <SidebarDefault>
                  <SidebarDefaultBody>
                    <BuildingTexture>
                      <StaticImage
                        src="../images/line-texture.png"
                        alt="specials building"
                      />
                    </BuildingTexture>
                    <RequestFormWrap>
                      <RequestForm
                        location={location}
                        thanksURL="/thank-you-get-in-touch"
                        productName={productData.title}
                      />
                    </RequestFormWrap>
                    <BuildingImg>
                      <StaticImage
                        src="../images/specials-building.png"
                        alt="specials building"
                      />
                    </BuildingImg>
                  </SidebarDefaultBody>
                  <PhoneCta
                    subTitle="Call Now to Learn About Other"
                    phoneTitle="Discounts & Specials on this Building."
                  />
                </SidebarDefault>
                {relatedProducts.length > 0 && (
                  <SimilarModal className="similar-modal">
                    <SimilarTitle>Check Out Similar Building Models</SimilarTitle>
                    <SimilarGrid className="similar-grid">
                      {relatedProducts.slice(0, 4).map((pd, i) => (
                        <SimilarGridItem key={i} className="similar-item">
                          <SimilarBuildingModal productData={pd.node} />
                        </SimilarGridItem>
                      ))}
                    </SimilarGrid>
                  </SimilarModal>
                )}
                <Recommendations />
              </Sidebar>
            </RightGrid>
          </Grid>
        </Container>
      </Section>
    </Layout>
  );
};

export default ProductTemplatePage;

export const pageQuery = graphql`
  query ProductTemplatePageQuery($id: String!) {
    contentfulProduct(id: { eq: $id }) {
      metaTitle
      metaDescription
      id
      skuNumber
      rto
      financingAvailable
      title
      image {
        gatsbyImageData(quality: 50, placeholder: BLURRED)
        fluid {
          srcWebp
        }
      }
      price
      width
      length
      height
      city
      state
      zipcode
      url
      buildingTags
      roofStyleType
      manufacturer
      gaugeTubing
      gaugePanels
      buildingCondition
      description {
        childMarkdownRemark {
          html
        }
      }
      productCategories {
        title
      }
      buildingTags
      roofStyleType
      manufacturer
      gaugeTubing
      gaugePanels
      buildingDescription {
        internal {
          content
        }
      }
    }
    allContentfulProduct {
      edges {
        node {
          id
          url
          rto
          financingAvailable
          skuNumber
          title
          image {
            gatsbyImageData(quality: 50, placeholder: BLURRED)
            fluid {
              srcWebp
            }
          }
          price
          width
          length
          height
          city
          state
          zipcode
          location {
            lat
            lon
          }
          buildingCondition
          description {
            childMarkdownRemark {
              html
            }
          }
          productCategories {
            title
          }
          buildingTags
          roofStyleType
          manufacturer
          gaugeTubing
          gaugePanels
        }
      }
    }
  }
`;
